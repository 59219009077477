import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GSError, LocationWithRelations } from '@gridscale/gsclient-js';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from './../../../environments/environment';
import { globalFailure, toastAction } from '../store/common.actions';
import { authFailure } from '../store/session/session.actions';
import { ConfigService } from '@gridscale/ingrid/helper/services/config.service';
import * as _ from 'lodash';
import { getCurrentUser } from '../store/user/user.selectors';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { getCurrentContract } from '../store/contract/contract.selectors';
import { combineLatest, from, Observable, of } from 'rxjs';
import { IIncident, StatuspalService } from '@gridscale/ingrid/helper/services/statuspal.service';
import { isSessionLoaded } from '../store/session/session.selectors';
import { TypedAction } from '@ngrx/store/src/models';
import { COMMIT_HASH } from './../../../commithash';
import { SkinConfigService } from './skinconfig.service';
import { UserRoleService } from './userrole.service';
import { getCurrentProject } from '../store/project/project.selectors';
import { getCurrentLocation } from '../store/location/location.selectors';
import { Project } from '../store/project/project.reducer';
import { Contract } from '../store/contract/contract.reducer';
import { User } from '../store/user/user.reducer';

// HTTP Codes that we have error messages defined in our translations
const HTTP_CODES_WITH_SPECIAL_ERROR_MESSAGE = [400, 401, 403, 404, 405, 409, 423, 424, 429, 500, 502, 503, 504, 8001];

// HTTP codes where we should try to parse the error message in the API response
const HTTP_CODES_PARSE_SERVER_ERROR_MESSAGE = [400, 403, 409, 422];

// HTTP Codes that were not reported to sentry
const HTTP_CODES_HIDE_FROM_SENTRY = [401, 403, 404, 409, 423, 424];

export interface LogEvent {
  /**
   * The exception (instance of `Error`) that occured
   * If this is omitted, `detail` must be given
   */
  exception?: Error;
  /**
   * The summary for the toast (if not given, the summary will be the `exception.name` or a default text)
   */
  summary?: string;
  /**
   * Detail text for the toast (if not given, the summary will be the `exception.message`)
   */
  detail?: string;
  /**
   * If this event should be logged to sentry (defaults to true)
   */
  logToSentry?: boolean;
  /**
   * Extra data to add to the sentry event
   */
  sentryExtra?: Record<string, string>;
  /**
   * Extra data to add to the sentry event
   */
  sentryTags?: Record<string, string>;
  /**
   * If a toast message should pop up (defaults to true)
   */
  displayToast?: boolean;
  /**
   * Severity of the toast message, defaults to 'error'
   */
  toastSeverity?: string;
  /**
   * the request id of the failed request (if available)
   */
  request_id?: string;
}

export interface TheError {
  error: Error,
  status: number;
  message: string;
  method: string; url:
  string; request_id?:
  string; requestPayload?: string;
  responseText?: string,
  stack?: string
}

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  private statusApi?: string;
  private statusPage?: string;



  isSessionLoaded$ = this.store.pipe(select(isSessionLoaded));
  isSessionLoaded = false;

  #currentContract?: Contract;
  #currentLocation?: LocationWithRelations;
  #currentProject?: Project;
  #currentUser?: User;



  constructor(
    private readonly store: Store,
    private readonly translate: TranslateService,
    private readonly actions$: ActionsSubject,
    private readonly configService: ConfigService,
    private readonly skinConfigService: SkinConfigService,
    private readonly statuspalService: StatuspalService,
    private readonly userRoleService: UserRoleService
  ) {
    this.isSessionLoaded$.subscribe((_session) => this.isSessionLoaded = _session);

    combineLatest([
      this.store.select(getCurrentUser),
      this.store.select(getCurrentContract),
      this.store.select(getCurrentProject),
      this.store.select(getCurrentLocation)
    ]).pipe(
    ).subscribe(([user, contract, project, currentLocation]) => {
      this.#currentContract = contract;
      this.#currentProject = project;
      this.#currentUser = user;
      this.#currentLocation = currentLocation;
    });


  }

  /**
   * Initializes the error handling (init sentry, watch for the ngrx globalError actions... )
   */
  init() {
    this.setupSentry();

    this.configService.ready$.subscribe(configReady => {
      if (!configReady) {
        return;
      }

      this.statusApi = _.get(this.skinConfigService, ['skinConfig', 'externalUrls', 'statusAPI']);
      this.statusPage = _.get(this.skinConfigService, ['skinConfig', 'externalUrls', 'statusPage']);
    });

    if (environment.sentryLogErrors) {
      this.actions$.subscribe(action => {
        Sentry.addBreadcrumb({
          category: 'ngrx-action',
          data: _.omit(action, 'type'),
          level: action.type.indexOf('Failure') >= 0 ? 'error' : 'log',
          type: 'info',
          timestamp: (new Date()).getTime() / 1000,
          message: action.type
        })
      });
    }

    // watch for store failures
    this.actions$.pipe(ofType(globalFailure)).pipe(
      switchMap(action => this.getTheError(action).pipe(map(theError => [action, theError] as [typeof action, TheError])))
    ).subscribe(([action, theError]) => {

      // normalize URL
      let extractedUUID;
      // remove UUIDs
      if (theError.url && theError.url.match(/(\b[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\b)/g)) {
        extractedUUID = RegExp.$1;
        theError.url = theError.url.replace(/\b[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\b/g, 'UUID');
      }
      // remove host
      if (theError.url && theError.url.match(/^http(s?):\/\/[^\/]+(\/.*)/)) {
        theError.url = RegExp.$2;
      }

      // normalize URL in message
      // remove UUIDs
      if (theError.message.match(/(\b[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\b)/g)) {
        extractedUUID = RegExp.$1;
        theError.message = theError.message.replace(/\b[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\b/g, 'UUID');
      }
      // remove host
      if (theError.message.match(/http(s?):\/\/[^\/]+(\/.*)/)) {
        theError.message = theError.message.replace(/http(s?):\/\/[^\/]+(\/.*)/, RegExp.$2);
      }

      // check if the error is whitelisted
      if (action.whitelist) {
        let whitelisted = false;
        action.whitelist.forEach(_whitelist => {
          if (typeof _whitelist === 'number' && _whitelist === theError.status) {
            whitelisted = true;
          } else if (typeof _whitelist === 'string' && (_.get(theError, 'responseText', '').indexOf(_whitelist) >= 0 || _.get(theError, 'message', '').indexOf(_whitelist) >= 0)) {
            whitelisted = true;
          }
        });
        if (whitelisted) {
          if (!environment.production) {
            console.warn('WHITELISTED ERROR OCCURED', theError.message);
          }
          return;
        }
      }

      let isAuthError = false;

      if (theError.status === 0 && theError.method != '') {
        // do not report network errors
        return;
      }

      if (theError.status === 401) {
        this.store.dispatch(authFailure());
        isAuthError = true;
      }

      let detail = this.translate.instant('ERRORS.UNKNOWN', { code: theError.status });
      if (HTTP_CODES_WITH_SPECIAL_ERROR_MESSAGE.indexOf(theError.status) >= 0) {
        detail = this.translate.instant('ERRORS.HTTP_' + String(theError.status)) + ' (' + theError.status + ')';
      }

      if (theError.status === 0 && _.isString(theError.message)) {
        detail = theError.message;
      }
      if (HTTP_CODES_PARSE_SERVER_ERROR_MESSAGE.indexOf(theError.status) >= 0) {
        detail = this.extraxtApiErrorMessage(theError, detail);
      }

      const error = new Error(theError.url);
      error.name = theError.message;

      // We Hide the 401 Toast if you never have been logged in. Thats for the case you return with credentials in your localStore!
      if (!this.isSessionLoaded && theError.status === 401) {
       // console.log('Hide Auth Error because we never have been logged in');
        return;
      }

      this.log({
        exception: error,
        toastSeverity: isAuthError ? 'warn' : 'error',
        displayToast: !action.supressToast && (['POST', 'PATCH', 'DELETE'].indexOf(theError.method) >= 0 || [500, 502, 503].indexOf(theError.status) < 0 || !environment.production),
        summary: this.translate.instant('ERRORS.REQUEST_ERROR'),
        request_id: theError.request_id,
        detail,
        sentryTags: {
          request_id: theError.request_id || 'unknown',
          extracted_uuid_from_url: extractedUUID ? extractedUUID : '-'
        },
        sentryExtra: {
          responseText: theError.responseText || 'unknown',
          requestPayload: theError.requestPayload || 'unknown',
          requestMethod: theError.method,
          httpStatus: String(theError.status),
          stack: theError.stack || 'unknown'
        },
        logToSentry: HTTP_CODES_HIDE_FROM_SENTRY.indexOf(theError.status) === -1 && document.location.hostname.indexOf('.nip.io') === -1
      });

      if (!environment.production) {
        console.error(
          'GLOBAL ERROR HANDLER',
          '[' + _.get(action, 'error.name', '[NO NAME]') + '] ' + _.get(action, 'error.message', '[NO MESSAGE]')
        );
        // if (error.stack && console.trace) {
        //   console.trace(error);
        // }
          // _.get(action, 'error.stack', '[NO STACKTRACE]'));
      }
    });
  }

  /**
   * Log an event  (displays toast message and/or log to sentry)
   */
  async log(event: LogEvent) {
    let acuteIncidents: IIncident[] = [];

    if (this.statusApi) {
      acuteIncidents = _.filter(
        await this.statuspalService
          .getImportant(this.statusApi)
          .pipe(
            filter(incidents => incidents !== undefined),
            take(1)
          )
          .toPromise(),
        i => i.statusClass !== 'scheduled'
      );
    }

    await from(this.translate.get('ERRORS.UNKNOWN_ERROR_HEAD').pipe(
      filter(translation => !_.isEmpty(translation)),
      take(1)
    ));

    const defaults: LogEvent = {
      summary: _.get(event, 'summary', _.get(event, 'exception.name', this.translate.instant('ERRORS.UNKNOWN_ERROR_HEAD'))),
      detail: _.get(event, 'detail', _.get(event, 'exception.message')),
      displayToast: true,
      logToSentry: true,
      toastSeverity: 'error'
    };
    const myEvent: LogEvent = {
      ...defaults,
      ...event
    };

    // function to actually display the toast (if sentry active, called in the `withScope` callback, otherwise immediately)
    const showToast = (eventId?: string) => {
      if (myEvent.displayToast) {
        this.store.dispatch(
          toastAction({
            severity: myEvent.toastSeverity,
            life: 20000,
            sticky: myEvent.toastSeverity === 'error',
            summary: myEvent.summary,
            detail: myEvent.detail,
            key: 'errorhandlerservice',
            data: {
              sentry_id: eventId,
              request_id: myEvent.request_id,
              incidents: acuteIncidents.length,
              statusPage: this.statusPage,
              // sentryFeedback: () => {
              //   Sentry.showReportDialog({
              //     title: this.translate.instant('ERRORS.SENTRY_FEEDBACK_TITLE'),
              //     subtitle: this.translate.instant('ERRORS.SENTRY_FEEDBACK_SUBTITLE'),
              //     successMessage: this.translate.instant('ERRORS.SENTRY_FEEDBACK_SENT'),
              //     lang: this.translate.currentLang,
              //     user: {
              //       email: this.currentUser ? this.currentUser.email || '' : '',
              //       name: this.currentUser ? this.currentUser.first_name + ' ' + this.currentUser.last_name : ''
              //     }
              //   });
              // }
            }
          })
        );
      }
    };

    if (environment.sentryLogErrors && myEvent.logToSentry && !document.location?.hostname?.match(/\.nip\.io$/)) {
      Sentry.withScope(scope => {
        let eventId;
        const extra: any = {};
        const tags: Record<string, string | number | boolean | undefined> = {
          'user_role_on_contract': this.userRoleService.getRole()
        };

        if (this.#currentContract) {
          tags['contract-uuid'] = this.#currentContract.object_uuid;
          tags['payment_enabled'] = this.#currentContract.payment_enabled;
        }

        if (this.#currentProject) {
          tags['project-uuid'] = this.#currentProject.object_uuid;

        }

        if (this.#currentLocation) {
          tags['location_hc_id'] = this.#currentLocation.hybrid_core_id;
          tags['location_site_name'] = this.#currentLocation.location_information?.site_name;
          tags['location_uuid'] = this.#currentLocation.object_uuid;

        }

        if (this.#currentUser) {
          tags['validated'] = this.#currentUser.validated;

          scope.setUser({
            email: this.#currentUser.email,
            id: this.#currentUser.object_uuid
          });
        }

        if (myEvent.sentryExtra) {
          Object.assign(extra, myEvent.sentryExtra);
        }
        if (myEvent.sentryTags) {
          Object.assign(tags, myEvent.sentryTags);
        }
        if (myEvent.exception === undefined && myEvent.detail) {
          tags.detail = myEvent.detail;
        }
        if (acuteIncidents.length) {
          extra.active_incidents = acuteIncidents;
        }
        scope.setExtras(extra);
        scope.setTags(tags);

        showToast(eventId);

        if (myEvent.exception) {
          eventId = Sentry.captureException(myEvent.exception);
        } else {
          eventId = Sentry.captureMessage(myEvent.summary || '???');
        }

      });
    } else {
      showToast();
    }
  }

  public getTheError(action: {
    error: Error | HttpErrorResponse;
    whitelist?: (string | number)[];
    method?: string;
    request_id?: string;
    requestPayload?: any;
  } & TypedAction<string>): Observable<TheError> {

    return new Observable(observer => {
      const theError: TheError = {
        error: action.error,
        status: 0,
        message: '',
        responseText: '',
        requestPayload: '',
        method: '',
        url: '',
        stack: _.get(action, 'error.stack', '-')
      };

      // normalize theError from different error sources
      if (action.error.name === 'GridscaleError' && (action.error as GSError).response) {
        if ((action.error as GSError).result.failureType === 'json') {
          theError.status = 8001;
        } else {
          theError.status = (action.error as GSError).response.status;
        }

        theError.message = (action.error as GSError).message;

        theError.url = (action.error as GSError).response.url;
        theError.request_id = (action.error as GSError).response.headers.get('x-request-id') || undefined;
        theError.method = _.get(action, 'error.result.response.request.method', '');
        theError.requestPayload = _.get(action, 'error.result.requestInit.body', '');
      } else if (action.error.name === 'HttpErrorResponse') {
        if ((action.error as HttpErrorResponse).error?.errors?.length > 0) {
          // New jsonapi.org API error response
          theError.message = '';
          _.forEach((action.error as HttpErrorResponse).error?.errors, error => {
            if (!_.isEmpty(theError.message)) {
              theError.message += ', ';
            }
            theError.message += error.title + (!_.isEmpty(error.detail) ? ' (' + error.detail + ')' : '');
          });
          theError.status = theError.status = _.get(action.error as HttpErrorResponse, 'status', 0);
          theError.url = _.get(action.error as HttpErrorResponse, 'error.url', '-');
          theError.method = action.method || '';
          theError.responseText = JSON.stringify(_.get(action.error as HttpErrorResponse, 'error.errors', []));

        } else if ((action.error as HttpErrorResponse).error instanceof ErrorEvent || ((action.error as HttpErrorResponse).status < 400 && (action.error as HttpErrorResponse).message)) {
          theError.status = _.get(action.error as HttpErrorResponse, 'error.status', 0);
          theError.message = _.isString(action.error.message) ? action.error.message : String(action.error);
          theError.responseText = _.get(action.error as HttpErrorResponse, 'error.text');
          theError.url = _.get(action.error as HttpErrorResponse, 'error.url', '-');
          theError.method = action.method || '';
        } else if ((action.error as HttpErrorResponse).error?.title && (action.error as HttpErrorResponse).error?.description) {
          // probably batch error response
          theError.status = _.get(action.error as HttpErrorResponse, 'status', 0);
          theError.message = (action.error as HttpErrorResponse).error?.description;
          theError.url = _.get(action.error as HttpErrorResponse, 'url', '-') || '-';
          theError.method = 'POST';

        } else {
          theError.status = (action.error as HttpErrorResponse).status;
          if (_.isObject((action.error as HttpErrorResponse).error)) {
            try {
              theError.responseText = JSON.stringify((action.error as HttpErrorResponse).error);
            } catch (e) { }
          }
          if (!theError.responseText) {
            theError.responseText = _.isString((action.error as HttpErrorResponse).error)
              ? (action.error as HttpErrorResponse).error
              : String((action.error as HttpErrorResponse));
          }
          theError.message = (action.error as HttpErrorResponse).message;
          theError.url = (action.error as HttpErrorResponse).url || '-';
          theError.method = action.method || '';
          theError.request_id = action.request_id;
          theError.requestPayload = action.requestPayload;
        }
      } else {
        theError.message = String(action.error);
        if (theError.message === '[object Object]') {
          try {
            theError.message = JSON.stringify(action.error);
          } catch (e) {
            theError.message = String(action.error);
          }
        }
      }

      if (action.error.name === 'GridscaleError' && (action.error as GSError).response) {
        from((action.error as GSError).response.text()).pipe(
          catchError(e => {
            return of('COULD NOT GET RESPONSE TEXT');
          }),
          take(1)
        ).subscribe(text => {
          theError.responseText = text;
          observer.next(theError);
          observer.complete();
        });
      } else {
        observer.next(theError);
        observer.complete();
      }
    });
  }

  public extraxtApiErrorMessage(theError: TheError, defaultText?: string) {
    // try to extract qualified message from the server response and display to the user
    let detail: string | undefined;
    if (defaultText) {
      detail = defaultText;
    }

    if (theError.error.name === 'HttpErrorResponse' && (
      (theError.error as HttpErrorResponse).error?.errors?.length > 0 ||
      (theError.error as HttpErrorResponse).error?.description
    )) {
      // we have the qualified error messag
      detail = theError.message;

    } else {
      try {
        const json = JSON.parse(theError.responseText || '{}');
        if (typeof json.description === 'string') {
          // try to parse totally crappy json
          const description = this.parseCrapJsonFromAPI(json.description);

          if (description && description.message && description.message.match) {
            let tmpMessage;
            if (description.message.match(/^\([0-9]{3},\s'([^']+)'\)$/)) {
              tmpMessage = RegExp.$1;
            } else {
              tmpMessage = description.message;
            }

            if (tmpMessage.match(/[a-z\s-]{10,}/)) {
              // seems to be a qualified message in the response which we can display
              detail = tmpMessage;
            }
          } else if (description?.match(/^[\w\s,._-]+$/)) {
            detail = description;
          }
        }
      } catch (e) { }
    }

    return detail;
  }

  /**
   * initializes sentry instance, configure scope etc.
   */
  private setupSentry() {
    if (!environment.sentryLogErrors || document.location?.hostname?.match(/\.nip\.io$/)) {
      return;
    }

    this.configService.ready$.subscribe(async configReady => {
      if (!configReady) {
        return;
      }

      Sentry.init({
        // dsn: this.configService.logger,
        dsn: 'https://8133409c95a646cda2b49adb420c9716@sentry-relay.gridscale.it/4504242241339392',
        autoSessionTracking: true,
        environment: window.location.host.match(/\.gridscale\.dev$/) || !environment.production ? 'staging' : 'production',
        // tslint:disable-next-line: no-string-literal
        release: 'cloud-panel-' + COMMIT_HASH,
        normalizeDepth: 10,
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production

        ignoreErrors: [
          'AUTH.LOGIN.INVALID_CREDENTIALS',
          'AUTH.INVITE.EMAIL_ALREADY_RELATED',
          '2 factor auth required - please provide an OTPToken.',
          'AUTH.GENERAL.ERROR',
          'AUTH.TWOFACTOR.ERROR',
          'GridscaleError: Network failure', // network error
          'ChunkLoadError', // network error
          'is already in the requested power state', // power request to a state the server is already in
          '0 Unknown Error', // network error
          'Http failure response for /api/v2/config: 404 OK', // unconfigured host,
          'GET | 401 |',
          'Uncaught (in promise)',
          'Easy/Payment', // (page not found)
          'ResizeObserver loop completed with undelivered notifications'
        ],
        beforeSend(event, hint) {
          // Ignore E2E testing
          if (window.navigator.userAgent.indexOf('Team JS Testing') !== -1) return null;

          if (event.environment === 'staging') return null;

          // credits: https://github.com/getsentry/sentry-javascript/issues/2292#issuecomment-554932519
          const isNonErrorException =
            (!!event?.exception?.values?.length && event.exception.values[0].value &&
              event.exception.values[0].value.startsWith('Non-Error exception captured')
            ) || (
              _.get(hint?.originalException, ['message'], '').startsWith('Non-Error exception captured')
            );

          if (isNonErrorException) {
            // We want to ignore those kind of errors
            return null;
          }

          return event;
        }
      });



      Sentry.configureScope(scope => {
        scope.setTag('ctrl-plane', '<!--#echo var="ssiCTRL_PLANE"-->'); // that ssi string is replaced in the compiled javascript by nginx on runtime
        scope.setTag('api_environment', this.configService.environmentApi);
        scope.setTag('core_api', this.configService.coreAPI);
        scope.setTag('vhost', this.configService.vHost);
        scope.setTag('partner-uuid', this.configService.partnerUuid);
      });


    });
  }

  /**
   * Try to parse the API response, claiming to be JSON but actually is CrapSON
   * @param crapJSON
   */
  private parseCrapJsonFromAPI(crapJSON: string): any {
    let object: any = '';
    let openSurrounder;
    let currentSurroundedString = '';
    let isKey = true;
    let currentKey;
    let lastWasEscape = false;
    let jsonStarted = false;
    for (let i = 0; i < crapJSON.length; ++i) {
      const c = crapJSON[i];

      let normalChar = true;
      if (!jsonStarted && c === '{') {
        jsonStarted = true;
        object = {};
        continue;
      }
      if (!jsonStarted) {
        object += c;
        continue;
      }
      if (c.match(/\s/) && !openSurrounder) {
        // whitespace, ignore
        continue;
      }

      switch (c) {
        case '}':
          if (!openSurrounder) {
            jsonStarted = false;
            normalChar = false;
          }
          break;

        case '\\':
          if (!lastWasEscape) {
            lastWasEscape = true;
            normalChar = false;
          }
          break;

        case '"':
        case "'":
          if (!lastWasEscape) {
            if (!openSurrounder) {
              // something was opened
              openSurrounder = c;
              normalChar = false;
            } else if (openSurrounder === c) {
              // something was closed, so ready read
              // key or value?
              if (isKey) {
                currentKey = currentSurroundedString;
              } else if (currentKey) {
                _.set(object, [currentKey], currentSurroundedString);
                currentKey = undefined;
              }
              currentSurroundedString = '';
              openSurrounder = undefined;

              normalChar = false;
            }
          } else {
            lastWasEscape = false;
          }
          break;

        case ':':
          if (!openSurrounder) {
            isKey = !isKey;
            normalChar = false;
          }
          break;

        case ',':
          if (!openSurrounder) {
            // next key value pair

            if (currentKey) {
              // was an unsurrounded value
              if (currentSurroundedString.match(/^[0-9]+$/)) {
                object[currentKey] = parseInt(currentSurroundedString, 10);
              } else if (currentSurroundedString === 'true') {
                object[currentKey] = true;
              } else if (currentSurroundedString === 'false') {
                object[currentKey] = false;
              }
            }

            isKey = true;
            currentKey = undefined;
            currentSurroundedString = '';

            normalChar = false;
          }
          break;

        default:
          if (!(openSurrounder || (!isKey && c.match(/^[0-9]+$/)))) {
            throw new Error('Even crappy JSON parser cannot parse: Invalid character "' + c + '" at position ' + i);
          }
      }
      if (normalChar) {
        if (openSurrounder || (!isKey && c.match(/^[0-9]+$/))) {
          currentSurroundedString += c;
        }
      }
    }

    return object;
  }
}
